import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

const Home = lazy(() => import('../pages/Home'))
const HomeDebug = lazy(() => import('../pages/HomeDebug'))
const Secret = lazy(() => import('../pages/Secret/Secret'))

enum ROUTES {
    HOME = '/webex',
    HOME_DEBUG = '/webex/debug',
    SECRET = '/webex/ciunas'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <Route path={ROUTES.SECRET} component={Secret} />
                        <Route path={ROUTES.HOME_DEBUG} component={HomeDebug} />
                        <Route
                            path={ROUTES.HOME}
                            component={Home}
                            exact={true}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
